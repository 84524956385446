<template>
  <v-form
    ref="viewPassForm"
    v-model="isValid"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="itsID"
            :rules="ITSValidationRule"
            label="ITS ID"
            outlined
            required
          />
          <v-text-field
            v-model="itsID"
            class="d-none"
            :rules="ITSValidationRule"
            label="ITS ID"
            outlined
            required
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-btn
            :loading="passBtnLoading"
            class="mt-2"
            large
            color="primary"
            :disabled="!isValid"
            cols="12"
            md="4"
            @click="$emit('getPassData',itsID)"
          >
            View/Manage Pass
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-row
      v-if="errMsg"
      justify="center mb-6"
    >
      <h4 class="error--text">
        {{ errMsg }}
      </h4>
    </v-row>
  </v-form>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'ViewPassForm',
  data: () => ({
    isValid: false,
    itsID: '',
    emptyRule: [v => !!v || 'This is required'],
    ITSValidationRule: [v => !!v || 'ITS is required', v => /^[0-9]{8}$/.test(v) || 'Please enter valid ITS ID'],
    errMsg: '',
    passBtnLoading: false
  }),
  computed: {
    ...mapGetters({ slots: 'slots/getAllUniqueSlots' })
  },
  mounted () {
    this.$store.dispatch('slots/fetchAllSlots')
  },
  methods: {
    validateForm () {
      this.$refs.viewPassForm.validate()
    }
  }
}
</script>

<style scoped>

</style>
