import * as firebase from '../../firebase.config'
import { fieldValue } from '../../firebase.config'
import moment from 'moment'

export const attendees = {
  namespaced: true,
  state: {
    allocatedUserList: []
  },
  actions: {
    async uploadITSData ({ commit }, data) {
      try {
        const batchedData = data.chunkify(500)
        let batch = firebase.db.batch()
        for (const userData of batchedData) {
          for (const user of userData) {
            const its = user.ITS_ID.toString()
            const userRef = await firebase.userCollection.doc(its)
            batch.set(userRef, {
              itsID: its,
              regName: user.Full_Name,
              mobile: user.Mobile,
              email: user.Email,
              gender: user.Gender
            })
          }
          await batch.commit()
          batch = firebase.db.batch()
        }
        return {
          type: 'success',
          message: 'Data successfully uploaded!'
        }
      } catch (e) {
        console.error(e)
        return {
          type: 'error',
          message: 'Error while uploading data!'
        }
      }
    },
    async allocatePass ({ commit }, form) {
      const snapshot = await firebase.attendeeListCollection.where('slotDate',
        '==', form.venue.slotDate).where('itsID', '==', form.itsID).get()
      try {
        if (!snapshot.empty) {
          return {
            type: 'error',
            message: 'Your pass has already been allocated for this day,' +
                     ' kindly visit view pass page to view your pass'
          }
        } else {
          const data = { }
          data.itsID = form.itsID
          data.slotDate = form.venue.slotDate
          data.regName = form.regName
          data.email = form.email
          data.mobile = form.mobile
          data.slotId = form.venue.slotId
          data.venue = form.venue.slotVenue
          data.date = form.venue.date
          data.gender = form.gender
          data.id = form.id
          await firebase.attendeeListCollection.doc(data.id).set(data)
          const decrement = fieldValue.increment(-1)
          await firebase.slotsCollection.doc(form.venue.slotId).update({
            slotQty: decrement
          })
          return {
            type: 'success',
            message: 'Allocation successful for ' + form.itsID
          }
        }
      } catch (e) {

      }
    },
    async getAttendeesBySlot ({ commit }, slotId) {
      try {
        const attendees = []
        firebase.attendeeListCollection.where('bethakSlot', '==', slotId)
          .get()
          .then((snapshot) => {
            snapshot.docs.forEach(doc => {
              attendees.push(doc.data())
            })
          })
        if (attendees) {
          commit('setUserList', attendees)
        }
      } catch (e) {

      }
    },
    async getPassesByITS ({ commit }, itsID) {
      try {
        const todaysDate = moment().format('YYYY-MM-DD')
        const data = await firebase.attendeeListCollection
          .where('itsID', '==', itsID)
          .where('date', '>=', todaysDate)
          .get()
        if (!data.empty) {
          const passList = []
          data.docs.forEach(doc => passList.push(doc.data()))
          return { status: 'success', data: passList }
        }
        return { status: 'not_found', data: [] }
      } catch (e) {
        return { status: 'fail', data: [] }
      }
    },
    async getAttendeesByDate ({ commit }, { date }) {
      try {
        const allAttendees = []
        const attendeeSnapshot = await firebase.attendeeListCollection.where(
          'date', '==', date).get()
        if (!attendeeSnapshot.empty) {
          attendeeSnapshot.forEach(doc => allAttendees.push(doc.data()))
        }
        commit('setUserList', allAttendees)
      } catch (e) {

      }
    },
    async deletePassById ({ commit }, item) {
      try {
        await firebase.attendeeListCollection.doc(item.id).delete()
        const increment = fieldValue.increment(1)
        await firebase.slotsCollection.doc(item.slotId).update({
          slotQty: increment
        })
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    async updatePass ({ commit }, { oldData, newData }) {
      try {
        const batch = firebase.db.batch()
        const oldSlotRef = await firebase.slotsCollection.doc(oldData.slotId)
        const newSlotRef = await firebase.slotsCollection.doc(newData.venue.slotId)
        const attendeeRef = await firebase.attendeeListCollection.doc(oldData.id)
        const increment = fieldValue.increment(1)
        const decrement = fieldValue.increment(-1)
        batch.update(oldSlotRef, {
          slotQty: increment
        })
        batch.update(newSlotRef, {
          slotQty: decrement
        })
        batch.update(attendeeRef, {
          venue: newData.venue.slotVenue,
          slotId: newData.venue.slotId
        })
        await batch.commit()
        return true
      } catch (e) {
        return false
      }
    }
  },
  mutations: {
    setUserList (state, val) {
      state.allocatedUserList = val
    }
  },
  getters: {}
}

// eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, 'chunkify', {
  value: function (chunkSize) {
    var R = []
    for (var i = 0; i < this.length; i += chunkSize) { R.push(this.slice(i, i + chunkSize)) }
    return R
  }
})
