<template>
  <v-container
    class="pa-6"
    fluid
  >
    <v-card
      class="mx-auto pass-card pa-10"
      max-width="600"
      min-height="400"
    >
      <v-card-text>
        <v-row justify="center">
          <h2>Gadeere Khum 1442H <br> Waaz Talaqqi (Burhani Masjid)</h2>
        </v-row>
        <v-row>
          <h3 class="mt-7 mb-4">
            Waaz Details:
          </h3>
        </v-row>
        <v-row>
          <h4 class="mx-1">
            Hijri Date :
          </h4>
          <span>{{ passData.venue.slotDate }}</span>
        </v-row>
        <v-row>
          <h4 class="mx-1">
            Date :
          </h4>
          <span>{{ passData.venue.date }}</span>
        </v-row>
        <v-row class="mb-4">
          <h4 class="mx-1">
            Block :
          </h4>
          <span>{{ passData.venue.slotVenue }}</span>
        </v-row>
        <v-row class="mb-4">
          <h4 class="mx-1">
            Time :
          </h4>
          <span>8:00 AM</span>
        </v-row>
        <v-divider />
        <v-row class="mt-4 mb-4">
          <h3>Personal Details:</h3>
        </v-row>
        <v-row>
          <h4 class="mx-1">
            ITS ID :
          </h4>
          <span>{{ passData.itsID }}</span>
        </v-row>
        <v-row>
          <h4 class="mx-1">
            Name :
          </h4>
          <span>{{ passData.regName }}</span>
        </v-row>
        <v-row>
          <h4 class="mx-1">
            Gender :
          </h4>
          <span>{{ passData.gender }}</span>
        </v-row>
        <v-divider />
        <v-row class="mt-4 mb-4">
          <span>Important Instructions</span>
        </v-row>
        <ul>
          <li>This pass is only for flow management in Burhani Masjid for Waaz Mubarak Talaqqi.</li>
          <li>
            Please reach the venue before Waaz time.
          </li>
          <li>
            Please take a screenshot of the pass. You will be allowed to enter on showing this pass.
          </li>
          <li>Government guidelines must be followed at all times.</li>
          <li>Wearing a mask while entering, exiting and sitting in Masjid is compulsory.</li>
          <li>Maintain social distance while in Masjid and also while entering and leaving premises.</li>
          <li>
            Mumineen with any symptom of Covid-19 should not come to Masjid and cancel the pass.
          </li>
        </ul>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'BethakPass',
  data: () => ({
    passData: {}
  }),
  computed: {},
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      this.passData = JSON.parse(this.$route.query.data)
    }
  }
}
</script>

<style scoped>
  .pass-card {
    background-image: url('~@/assets/border.png');
    background-size: 100% 100%;
  }
</style>
