<template>
  <v-container
    class="pa-6"
    fluid
  >
    <v-card
      class="mx-auto"
      max-width="960"
    >
      <v-card-text class="mb-6">
        <h2 class="pb-6">
          Ashara Mubarakah 1443H Bangalore - Upload ITS Data
        </h2>
        <v-divider class="py-6" />
        <v-file-input
          v-model="file"
          :full-width="false"
          :show-size="1000"
          accept="text/csv"
          color="primary"
          counter
          label="File input"
          outlined
          placeholder="Select your file"
          prepend-icon=""
          suffix="Browse"
        >
          <template #selection="{ text }">
            <v-chip
              color="primary"
              dark
              label
              small
            >
              {{ text }}
            </v-chip>
          </template>
        </v-file-input>
        <v-btn
          :disabled="!file"
          class="primary mx-2"
          :loading="csvLoader"
          @click="parseCSV"
        >
          Upload File
        </v-btn>
        <v-divider class="py-6" />
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :color="
        snackType === 'success'
          ? 'green'
          : snackType === 'error'
            ? 'red'
            : 'yellow'
      "
      :timeout="2000"
    >
      {{ snackText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import Papa from 'papaparse'
export default {
  name: 'DataUpload',
  data () {
    return {
      snackbar: false,
      snackText: '',
      csvLoader: false,
      file: '',
      snackType: 'success'
    }
  },
  methods: {
    parseCSV () {
      const vm = this
      vm.csvLoader = true
      Papa.parse(this.file, {
        worker: true,
        header: true,
        dynamicTyping: true,
        complete: async function (results) {
          const result = await vm.$store.dispatch('attendees/uploadITSData', results.data)
          vm.snackType = result.type
          vm.snackText = result.message
          vm.snackbar = true
          vm.csvLoader = false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
