import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: 'AIzaSyBFczbS1QM8KtvJivsf8hwoQpelDT066rM',
  authDomain: 'vajebaat-app.firebaseapp.com',
  projectId: 'vajebaat-app',
  storageBucket: 'vajebaat-app.appspot.com',
  messagingSenderId: '877481383362',
  appId: '1:877481383362:web:c9af4e8168794b3de2f25b',
  measurementId: 'G-FYJ1B60K4T'
}
/* const firebaseConfig = {
  apiKey: 'AIzaSyAa_rwmkeHe_dKJOgEcbIWnsCcbwEgSpm8',
  authDomain: 'ashara-pass-allocation-app.firebaseapp.com',
  projectId: 'ashara-pass-allocation-app',
  storageBucket: 'ashara-pass-allocation-app.appspot.com',
  messagingSenderId: '13785318873',
  appId: '1:13785318873:web:6cbacd20e8b023d5c045c8',
  measurementId: 'G-WF6LS04R5T'
} */
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const fieldValue = firebase.firestore.FieldValue
const auth = firebase.auth()

// collection references
const slotsCollection = db.collection('slots')
const attendeeListCollection = db.collection('attendees')
const purposeCollection = db.collection('purpose')
const userCollection = db.collection('users')

export {
  fieldValue, db, auth, slotsCollection, attendeeListCollection, purposeCollection, userCollection
}
