<template>
  <v-container
    class="pa-6"
    fluid
  >
    <v-card
      class="mx-auto"
      max-width="960"
    >
      <h3 class="pa-6">
        Bethak Attendees (Admin)
      </h3>
      <v-col>
        <v-row justify="space-between">
          <v-col
            class="d-flex"
          >
            <v-select
              v-model="bethakSlot"
              :items="getFilteredSlots"
              item-text="date"
              item-value="date"
              label="Bethak Slot"
              outlined
            />
          </v-col>
          <v-btn
            v-if="bethakSlot"
            :loading="refreshLoader"
            class="d-flex mr-4 mt-4"
            color="primary"
            @click="getAttendees"
          >
            <span>Search</span>
          </v-btn>
          <v-btn
            v-if="bethakSlot && attendees.allocatedUserList.length > 0"
            :loading="downloadLoader"
            class="d-flex mr-4 mt-4"
            color="primary"
            @click="generateCsv"
          >
            <span>Download CSV</span>
            <v-icon class="ml-1">
              mdi-cloud-download
            </v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-data-table
        :headers="headers"
        :items="attendees.allocatedUserList"
        :loading="tableLoader"
        class="elevation-1"
        multi-sort
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { removeDuplicates } from '../../utils/removeDuplicates'

const JSONToCSV = require('json2csv').parse

export default {
  name: 'Attendees',
  data: () => ({
    bethakSlotList: [],
    tableLoader: false,
    refreshLoader: false,
    downloadLoader: false,
    bethakSlot: '',
    purposeId: '',
    headers: [
      {
        text: 'ITS',
        align: 'start',
        sortable: false,
        value: 'itsID'
      },
      {
        text: 'Name',
        value: 'regName'
      },
      {
        text: 'Email',
        sortable: false,
        value: 'email'
      },
      {
        text: 'Mobile',
        sortable: false,
        value: 'mobile'
      },
      {
        text: 'Slot Date',
        value: 'slotDate'
      },
      {
        text: 'Venue',
        value: 'venue'
      }
    ]
  }),
  computed: {
    ...mapState(['attendees']),
    ...mapGetters({ getAdminSlots: 'slots/getAllAdminSlots' }),
    getFilteredSlots () {
      return removeDuplicates(this.getAdminSlots, 'date')
    }
  },
  mounted () {
    this.getSlots()
  },
  methods: {
    getSlots () {
      this.$store.dispatch('slots/fetchAllSlots')
    },
    getAttendees () {
      this.tableLoader = true
      this.refreshLoader = true
      this.$store.dispatch('attendees/getAttendeesByDate', {
        date: this.bethakSlot
      })
      this.tableLoader = false
      this.refreshLoader = false
    },
    async generateCsv () {
      const head = ['itsID', 'regName', 'email', 'mobile', 'slotDate', 'venue']
      const data = [...this.attendees.allocatedUserList]
      const csv = JSONToCSV(data, { fields: head })
      const anchor = document.createElement('a')
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
      anchor.target = '_blank'
      anchor.download = this.bethakSlot + '_attendees.csv'
      anchor.click()
    }
  }
}
</script>

<style scoped>

</style>
