import * as firebase from '../../firebase.config'
import { getDate } from '../utils/DateUtils'

export const slots = {
  namespaced: true,
  state: {
    allSlots: [],
    adminSlots: [],
    venues: {
      Male: ['Main Masjid - Gents',
        'Sehan - Gents',
        'Sehan - Rahat Block',
        'Imadi Hall (Mawaid Ground Floor) - Gents'
      ],
      Female: [
        '1st Floor - Ladies',
        '2nd  Floor - Ladies',
        'Sehan - Rahat Block',
        'Badri Hall (Mawaid Ground Floor) - Ladies'
      ]
    }
  },
  actions: {
    async fetchAllSlots ({ commit }) {
      const todaysDate = getDate(new Date())
      const slotList = []
      const adminSlots = []
      firebase.slotsCollection.orderBy('date', 'asc').get().then(snapshot => {
        snapshot.forEach(doc => {
          const slot = doc.data()
          const slotDate = getDate(slot.date)
          const isSlotActive = slot.isActive
          const slotQty = slot.slotQty
          if (slotDate >= todaysDate) {
            adminSlots.push(slot)
            if (isSlotActive && slotQty > 0) {
              slotList.push(slot)
            }
          }
        })
      })
      commit('setAllSlots', slotList)
      commit('setAllAdminSlots', adminSlots)
    },
    async createSlot ({ commit }, form) {
      try {
        await firebase.slotsCollection.doc(form.slotId).set(form)
        commit('pushToSlotList', form)
        return true
      } catch (e) {
        return false
      }
    },
    async updateSlot ({ commit }, slot) {
      try {
        await firebase.slotsCollection.doc(slot.slotId).update(slot)
        commit('updateSlotList', slot)
        return true
      } catch (e) {
        return false
      }
    },
    async deleteSlot ({ commit }, slotId) {
      try {
        await firebase.slotsCollection.doc(slotId).delete()
        // const attendeeDoc = await
        // firebase.attendeeListCollection.where("slotId",'==',slotId).get();
        // if(attendeeDoc.docs.length > 0){ attendeeDoc.forEach(element => {
        // element.ref.delete(); }) }
        commit('deleteSlotFromList', slotId)
        return true
      } catch (e) {
        return false
      }
    },
    async getCurrentSlot ({ commit }, purposeId) {
      const todaysDate = getDate(new Date())
      const filteredDocs = []
      firebase.slotsCollection.where('purposeId', '==', purposeId).orderBy(
        'date', 'asc').orderBy('slot_time', 'asc').get().then(snapshot => {
        if (snapshot.docs) {
          snapshot.docs.forEach(val => {
            const data = val.data()
            const date = getDate(data.date)
            if (data.slotQty > 0 && date >= todaysDate) {
              filteredDocs.push(data)
            }
          })
          if (filteredDocs) {
            commit('setCurrentSlot', filteredDocs[0])
          }
        }
      })
    }
  },
  mutations: {
    setCurrentSlot (state, val) {
      state.currentSlot = val
    },
    pushToSlotList (state, val) {
      state.allSlots.push(val)
      state.adminSlots.push(val)
    },
    setAllSlots (state, val) {
      state.allSlots = val
    },
    setAllAdminSlots (state, val) {
      state.adminSlots = val
    },
    updateSlotList (state, val) {
      const index = state.allSlots.findIndex(item => item.slotId === val.slotId)
      const index2 = state.adminSlots.findIndex(item => item.slotId === val.slotId)
      state.allSlots.splice(index, 1, val)
      state.adminSlots.splice(index2, 1, val)
    },
    deleteSlotFromList (state, val) {
      const index = state.allSlots.findIndex(item => item.slotId === val)
      const index2 = state.adminSlots.findIndex(item => item.slotId === val)
      state.allSlots.splice(index, 1)
      state.adminSlots.splice(index2, 1)
    }
  },
  getters: {
    getAllVenues: (state) => {
      return [...state.venues.Male, ...state.venues.Female]
    },
    getVenuesByGender: (state) => (gender) => {
      return state.venues[gender]
    },
    getAllSlotsByGender: (state) => (gender) => {
      let type = 'Gents'
      if (gender === 'Male') {
        type = 'Gents'
      } else {
        type = 'Ladies'
      }
      return state.allSlots.filter(slot => slot.slotVenue.includes(type) || slot.slotVenue.includes('Rahat'))
    },
    getAllSlotsByDateAndGender: (state) => (gender, date) => {
      let type = 'Gents'
      if (gender === 'Male') {
        type = 'Gents'
      } else {
        type = 'Ladies'
      }
      const filteredSlots = state.allSlots.filter(slot => slot.slotVenue.includes(type) || slot.slotVenue.includes('Rahat'))
      return filteredSlots.filter(slot => slot.date === date)
    },
    getAllSlots: (state) => {
      return state.allSlots
    },
    getAllAdminSlots: (state) => {
      return state.adminSlots
    },
    getAllUniqueSlots: (state) => {
      return [...new Set(state.allSlots)]
    }
  }
}
