export function VajebaatFormTemplate (data) {
  return `<!DOCTYPE HTML
  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns='http://www.w3.org/1999/xhtml' xmlns:v='urn:schemas-microsoft-com:vml'
  xmlns:o='urn:schemas-microsoft-com:office:office'>

<head>
  <meta http-equiv='Content-Type' content='text/html; charset=UTF-8'>
  <meta name='viewport' content='width=device-width, initial-scale=1.0'>
  <meta name='x-apple-disable-message-reformatting'>
  <!--[if !mso]><!-->
  <meta http-equiv='X-UA-Compatible' content='IE=edge'>
  <!--<![endif]-->
  <title></title>

  <style type='text/css'>
    @media (max-width: 480px) {
      #u_row_1 .v-row-background-color {
        background-color: #ffffff !important;
      }

      #u_row_1.v-row-background-color {
        background-color: #ffffff !important;
      }

      #u_row_1 .v-row-background-image--inner {
        background-position: center top !important;
        background-repeat: no-repeat !important;
      }

      #u_row_1 .v-row-background-image--outer {
        background-position: center top !important;
        background-repeat: no-repeat !important;
      }

      #u_row_1.v-row-background-image--outer {
        background-position: center top !important;
        background-repeat: no-repeat !important;
      }

      #u_row_1.v-row-padding--vertical {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
      }

      #u_column_1 .v-col-padding {
        padding: 10px !important;
      }

      #u_content_heading_4 .v-font-size {
        font-size: 15px !important;
      }

      #u_content_heading_4 .v-line-height {
        line-height: 200% !important;
      }

      #u_content_heading_5 .v-font-size {
        font-size: 13px !important;
      }

      #u_content_text_1 .v-line-height {
        line-height: 100% !important;
      }

      #u_content_text_2 .v-line-height {
        line-height: 100% !important;
      }

      #u_content_text_3 .v-line-height {
        line-height: 100% !important;
      }

      #u_content_heading_6 .v-font-size {
        font-size: 13px !important;
      }

      #u_content_heading_6 .v-line-height {
        line-height: 100% !important;
      }

      #u_content_text_4 .v-line-height {
        line-height: 100% !important;
      }

      #u_content_text_5 .v-line-height {
        line-height: 100% !important;
      }

      #u_content_text_6 .v-line-height {
        line-height: 100% !important;
      }

      #u_content_text_9 .v-line-height {
        line-height: 100% !important;
      }

      #u_content_text_8 .v-line-height {
        line-height: 100% !important;
      }

      #u_content_text_7 .v-line-height {
        line-height: 100% !important;
      }

      #u_content_html_2 .v-container-padding-padding {
        padding: 10px !important;
      }

      #u_content_text_12 .v-container-padding-padding {
        padding: 0px !important;
      }

      #u_content_text_11 .v-container-padding-padding {
        padding: 0px !important;
      }
    }

    @media only screen and (min-width: 520px) {
      .u-row {
        width: 500px !important;
      }

      .u-row .u-col {
        vertical-align: top;
      }

      .u-row .u-col-100 {
        width: 500px !important;
      }

    }

    @media (max-width: 520px) {
      .u-row-container {
        max-width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
      }

      .u-row .u-col {
        min-width: 320px !important;
        max-width: 100% !important;
        display: block !important;
      }

      .u-row {
        width: calc(100% - 40px) !important;
      }

      .u-col {
        width: 100% !important;
      }

      .u-col>div {
        margin: 0 auto;
      }

      .no-stack .u-col {
        min-width: 0 !important;
        display: table-cell !important;
      }

      .no-stack .u-col-100 {
        width: 100% !important;
      }

    }

    body {
      margin: 0;
      padding: 0;
    }

    table,
    tr,
    td {
      vertical-align: top;
      border-collapse: collapse;
    }

    p {
      margin: 0;
    }

    .ie-container table,
    .mso-container table {
      table-layout: fixed;
    }

    * {
      line-height: inherit;
    }

    a[x-apple-data-detectors='true'] {
      color: inherit !important;
      text-decoration: none !important;
    }

    @media (max-width: 480px) {
      .hide-mobile {
        display: none !important;
        max-height: 0px;
        overflow: hidden;
      }

    }
  </style>
</head>

<body class='clean-body' style='margin: 0;padding: 0;-webkit-text-size-adjust: 100%;background-color: #ffffff'>
  <!--[if IE]><div class="ie-container"><![endif]-->
  <!--[if mso]><div class="mso-container"><![endif]-->
  <table
    style='border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: #ffffff;width:100%'
    cellpadding='0' cellspacing='0'>
    <tbody>
      <tr style='vertical-align: top'>
        <td style='word-break: break-word;border-collapse: collapse !important;vertical-align: top'>
          <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color: #ffffff;"><![endif]-->


          <div id='u_row_1'
            class='u-row-container v-row-padding--vertical v-row-background-image--outer v-row-background-color'
            style="padding: 0px;background-repeat: no-repeat;background-position: center top;background-color: transparent">
            <div class='u-row no-stack'
              style='Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;background-image: url(https://s3.amazonaws.com/unroll-images-production/projects%2F0%2F1619698778387-563875) !important; background-size: 100% 100%;'>
              <div class='v-row-background-image--inner'
                style='border-collapse: collapse;display: table;width: 100%;background-color: transparent;'>
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td class="v-row-background-image--outer v-row-background-color" style="padding: 0px;background-image: url('images/image-1.png');background-repeat: no-repeat;background-position: center top;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr class="v-row-background-image--inner" style="background-color: transparent;"><![endif]-->

                <!--[if (mso)|(IE)]><td align="center" width="500" class="v-col-padding" style="width: 500px;padding: 15px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
                <div id='u_column_1' class='u-col u-col-100'
                  style='max-width: 320px;min-width: 500px;display: table-cell;vertical-align: top;'>
                  <div style='width: 100% !important;'>
                    <!--[if (!mso)&(!IE)]><!-->
                    <div class='v-col-padding'
                      style='padding: 15px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;'>
                      <!--<![endif]-->

                      <table id='u_content_heading_4' style='font-family:arial,helvetica,sans-serif;'
                        role='presentation' cellpadding='0' cellspacing='0' width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td class='v-container-padding-padding'
                              style='overflow-wrap:break-word;word-break:break-word;padding:36px 10px 10px;font-family:arial,helvetica,sans-serif;'
                              align='left'>

                              <h1 class='v-line-height v-font-size'
                                style='margin: 0px; color: #000000; line-height: 200%; text-align: center; word-wrap: break-word; font-weight: normal; font-family: arial,helvetica,sans-serif; font-size: 20px;'>
                                <strong> Gadeere Khum 1442H <br> Waaz Talaqqi (Burhani Masjid)</strong>
                              </h1>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table id='u_content_heading_5' style='font-family:arial,helvetica,sans-serif;'
                        role='presentation' cellpadding='0' cellspacing='0' width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td class='v-container-padding-padding'
                              style='overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;'
                              align='left'>

                              <h4 class='v-line-height v-font-size'
                                style='margin: 0px; color: #000000; line-height: 100%; text-align: left; word-wrap: break-word; font-weight: normal; font-family: arial,helvetica,sans-serif; font-size: 16px;'>
                                <strong>Waaz Details:</strong>
                              </h4>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table id='u_content_text_1' style='font-family:arial,helvetica,sans-serif;' role='presentation'
                        cellpadding='0' cellspacing='0' width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td class='v-container-padding-padding'
                              style='overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;'
                              align='left'>

                              <div class='v-line-height'
                                style='color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;'>
                                <span style='font-size: 14px; line-height: 12px;'> <strong> Hijri Date : </strong>
                                  ${data.venue.slotDate} </span>
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table id='u_content_text_2' style='font-family:arial,helvetica,sans-serif;' role='presentation'
                        cellpadding='0' cellspacing='0' width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td class='v-container-padding-padding'
                              style='overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;'
                              align='left'>

                              <div class='v-line-height'
                                style='color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;'>
                                <span style='font-size: 14px; line-height: 12px;'> <strong> Date : </strong>
                                  ${data.venue.date} </span>
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>
                      
                       <table id='u_content_text_2' style='font-family:arial,helvetica,sans-serif;' role='presentation'
                        cellpadding='0' cellspacing='0' width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td class='v-container-padding-padding'
                              style='overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;'
                              align='left'>

                              <div class='v-line-height'
                                style='color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;'>
                                <span style='font-size: 14px; line-height: 12px;'> <strong> Block : </strong>
                                  ${data.venue.slotVenue} </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      
                       <table id='u_content_text_2' style='font-family:arial,helvetica,sans-serif;' role='presentation'
                        cellpadding='0' cellspacing='0' width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td class='v-container-padding-padding'
                              style='overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;'
                              align='left'>

                              <div class='v-line-height'
                                style='color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;'>
                                <span style='font-size: 14px; line-height: 12px;'> <strong> Time : </strong>
                                 8:00 AM </span>
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table style='font-family:arial,helvetica,sans-serif;' role='presentation' cellpadding='0'
                        cellspacing='0' width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td class='v-container-padding-padding'
                              style='overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;'
                              align='left'>

                              <table height='0px' align='center' border='0' cellpadding='0' cellspacing='0' width='100%'
                                style='border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 1px solid #BBBBBB;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%'>
                                <tbody>
                                  <tr style='vertical-align: top'>
                                    <td
                                      style='word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%'>
                                      <span>&#160;</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table id='u_content_heading_6' style='font-family:arial,helvetica,sans-serif;'
                        role='presentation' cellpadding='0' cellspacing='0' width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td class='v-container-padding-padding'
                              style='overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;'
                              align='left'>

                              <h4 class='v-line-height v-font-size'
                                style='margin: 0px; color: #000000; line-height: 140%; text-align: left; word-wrap: break-word; font-weight: normal; font-family: arial,helvetica,sans-serif; font-size: 16px;'>
                                <strong>Personal Details:</strong>
                              </h4>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table id='u_content_text_4' style='font-family:arial,helvetica,sans-serif;' role='presentation'
                        cellpadding='0' cellspacing='0' width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td class='v-container-padding-padding'
                              style='overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;'
                              align='left'>

                              <div class='v-line-height'
                                style='color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;'>
                                <span style='font-size: 14px; line-height: 12px;'> <strong> ITS : </strong>
                                  ${data.itsID} </span>
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>


                      <table id='u_content_text_6' style='font-family:arial,helvetica,sans-serif;' role='presentation'
                        cellpadding='0' cellspacing='0' width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td class='v-container-padding-padding'
                              style='overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;'
                              align='left'>

                              <div class='v-line-height'
                                style='color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;'>
                                <span style='font-size: 14px; line-height: 12px;'> <strong> Name : </strong>
                                  ${data.regName} </span>
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>
                       <table id='u_content_text_6' style='font-family:arial,helvetica,sans-serif;' role='presentation'
                        cellpadding='0' cellspacing='0' width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td class='v-container-padding-padding'
                              style='overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;'
                              align='left'>

                              <div class='v-line-height'
                                style='color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;'>
                                <span style='font-size: 14px; line-height: 12px;'> <strong> Gender : </strong>
                                  ${data.gender} </span>
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table style='font-family:arial,helvetica,sans-serif;' role='presentation' cellpadding='0'
                        cellspacing='0' width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td class='v-container-padding-padding'
                              style='overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;'
                              align='left'>

                              <table height='0px' align='center' border='0' cellpadding='0' cellspacing='0' width='100%'
                                style='border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 1px solid #BBBBBB;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%'>
                                <tbody>
                                  <tr style='vertical-align: top'>
                                    <td
                                      style='word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%'>
                                      <span>&#160;</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table style='font-family:arial,helvetica,sans-serif;' role='presentation' cellpadding='0'
                        cellspacing='0' width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td class='v-container-padding-padding'
                              style='overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;'
                              align='left'>

                              <h4 class='v-line-height v-font-size'
                                style='margin: 0px; color: #000000; line-height: 140%; text-align: left; word-wrap: break-word; font-weight: normal; font-family: arial,helvetica,sans-serif; font-size: 16px;'>
                                <strong>Important Instructions</strong>
                              </h4>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table id='u_content_html_2' style='font-family:arial,helvetica,sans-serif;' role='presentation'
                        cellpadding='0' cellspacing='0' width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td class='v-container-padding-padding'
                              style='overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;'
                              align='left'>

                              <div>
                                <ul data-v-bd87ce68=''>
                                  <li data-v-bd87ce68=''>This pass is only for flow management in Burhani Masjid bethak.
                                  </li>
                                  <li data-v-bd87ce68=''>Please reach the venue on time, you will only be allowed to
                                    enter the venue during the allocated time. </li>
                                  <li data-v-bd87ce68=''>Please take a screenshot of the pass. You will be allowed to
                                    enter on showing this pass. </li>
                                  <li data-v-bd87ce68=''>Government guidelines must be followed at all times.</li>
                                  <li data-v-bd87ce68=''>Wearing a mask while entering, exiting and sitting in Masjid is
                                    compulsory.</li>
                                  <li data-v-bd87ce68=''>Maintain social distance while in Masjid and also while
                                    entering and leaving premises.</li>
                                  <li data-v-bd87ce68=''>Mumineen with any symptom of Covid-19 should not come to Masjid
                                    and cancel the pass by contacting vajebaat representatives. </li>
                                </ul>
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table id='u_content_text_12' style='font-family:arial,helvetica,sans-serif;' role='presentation'
                        cellpadding='0' cellspacing='0' width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td class='v-container-padding-padding'
                              style='overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;'
                              align='left'>

                              <div class='v-line-height'
                                style='color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;'>

                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table style='font-family:arial,helvetica,sans-serif;' role='presentation' cellpadding='0'
                        cellspacing='0' width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td class='v-container-padding-padding'
                              style='overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;'
                              align='left'>

                              <div class='v-line-height'
                                style='color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;'>

                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table id='u_content_text_11' class='hide-mobile' style='font-family:arial,helvetica,sans-serif;'
                        role='presentation' cellpadding='0' cellspacing='0' width='100%' border='0'>
                        <tbody>
                          <tr>
                            <td class='v-container-padding-padding'
                              style='overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;'
                              align='left'>
                              <div class='v-line-height'
                                style='color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;'>
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <!--[if (!mso)&(!IE)]><!-->
                    </div>
                    <!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td><![endif]-->
                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>
          <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
        </td>
      </tr>
    </tbody>
  </table>
  <!--[if mso]></div><![endif]-->
  <!--[if IE]></div><![endif]-->
</body>

</html>`
}
