<template>
  <v-container
    class="pa-6"
    fluid
  >
    <v-card
      class="mx-auto"
      max-width="960"
    >
      <h3 class="pa-6">
        Ashara Mubarakah 1443H Bangalore - Waaz Slots
      </h3>
      <v-form
        ref="slotForm"
        v-model="valid"
        @submit.prevent
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="form.slotDate"
                :rules="emptyRule"
                label="Hijri Date"
                outlined
                required
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="form.date"
                :rules="emptyRule"
                label="Gregorian Date"
                outlined
                required
                type="date"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="form.slotVenue"
                :items="venues"
                :rules="emptyRule"
                label="Slot Venue"
                outlined
                required
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="form.slotQty"
                :rules="emptyRule"
                label="Quota"
                outlined
                required
                type="number"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-btn
                :loading="loading"
                class="d-flex"
                color="primary"
                cols="12"
                sm="6"
                @click="createSlot()"
              >
                Create Slot
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-data-table
        :headers="headers"
        :items="getAdminSlots"
        class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            class="mr-2"
            size="25"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            size="25"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:item.isActive="{item}">
          <v-switch
            v-model="item.isActive"
            @change="updateActiveStatus($event,item)"
          />
        </template>
      </v-data-table>

      <v-dialog
        v-model="dialogDelete"
        max-width="500px"
      >
        <v-card>
          <v-card-title>Are you sure you want to delete this item?</v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="closeDelete"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="deleteItemConfirm"
            >
              Confirm
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogEdit"
        max-width="500px"
      >
        <v-card>
          <v-card-title>Edit Slot</v-card-title>
          <v-card-text>
            <v-form
              ref="editingSlotForm"
              v-model="isEditingFormValid"
            >
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="editingItem.slotDate"
                  :rules="emptyRule"
                  label="Hijri Date"
                  outlined
                  required
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="editingItem.date"
                  :rules="emptyRule"
                  disabled
                  label="Gregorian Date"
                  outlined
                  required
                  type="date"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-select
                  v-model="editingItem.slotVenue"
                  :items="venues"
                  :rules="emptyRule"
                  disabled
                  label="Slot Venue"
                  outlined
                  required
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="editingItem.slotQty"
                  :rules="emptyRule"
                  label="Quota"
                  outlined
                  required
                  type="number"
                />
              </v-col>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="dialogEdit = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="updateSlot"
            >
              Save
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
    >
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { mapGetters } from 'vuex'

export default {
  name: 'Slots',
  data: () => ({
    dialogDelete: false,
    editingItem: {},
    isEditingFormValid: false,
    snackbarText: '',
    snackbar: false,
    valid: 'false',
    loading: false,
    dialogEdit: false,
    form: {
      slotDate: '',
      date: '',
      slotQty: 0,
      slotId: '',
      slotVenue: '',
      isActive: false
    },
    emptyRule: [v => !!v || 'This is required'],
    headers: [
      {
        text: 'Hijri Date',
        align: 'start',
        sortable: true,
        value: 'slotDate'
      },
      {
        text: 'Gregorian Date',
        sortable: true,
        value: 'date'
      },
      {
        text: 'Venue',
        value: 'slotVenue'
      },
      {
        text: 'Total Availability',
        value: 'slotQty'
      },
      {
        text: 'Is Active',
        value: 'isActive',
        sortable: false
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false
      }
    ]
  }),
  computed: {
    ...mapGetters({
      getPurposeById: 'purpose/getPurposeById',
      venues: 'slots/getAllVenues',
      getAdminSlots: 'slots/getAllAdminSlots'
    })
  },
  mounted () {
    this.getSlots()
  },

  methods: {
    async createSlot () {
      this.loading = true
      this.snackbarText = ''
      this.validateForm('new')
      if (this.valid) {
        const data = { ...this.form }
        data.slotId = uuidv4()
        data.slotQty = parseInt(this.form.slotQty)
        const slotResult = await this.$store.dispatch('slots/createSlot', data)
        if (slotResult) {
          this.snackbar = true
          this.snackbarText = 'Slot created successfully'
          this.$refs.slotForm.reset()
        } else {
          this.snackbar = true
          this.snackbarText = 'Unable to create slot'
        }
      } else {
        this.snackbar = true
        this.snackbarText = 'Unable to create slot'
      }
      this.loading = false
    },
    validateForm (type) {
      if (type === 'new') {
        this.$refs.slotForm.validate()
      } else {
        this.$refs.editingSlotForm.validate()
      }
    },
    editItem (item) {
      this.editingItem = item
      this.dialogEdit = true
    },
    async updateActiveStatus (event, item) {
      const slotResult = await this.$store.dispatch('slots/updateSlot', {
        ...item,
        isActive: event
      })
      if (slotResult) {
        this.snackbar = true
        this.snackbarText = 'Slot active status changed successfully'
        this.dialogEdit = false
      } else {
        this.snackbar = true
        this.snackbarText = 'Unable to update active status'
      }
    },
    async updateSlot () {
      this.validateForm('update')
      if (this.isEditingFormValid) {
        this.editingItem.slotQty = parseInt(this.editingItem.slotQty)
        this.editingItem.isActive = false
        const slotResult = await this.$store.dispatch('slots/updateSlot', this.editingItem)
        if (slotResult) {
          this.snackbar = true
          this.snackbarText = 'Slot updated successfully'
          this.dialogEdit = false
        } else {
          this.snackbar = true
          this.snackbarText = 'Unable to update slot'
        }
      }
    },
    deleteItem (item) {
      this.dialogDelete = true
      this.editingItem = item
    },
    async deleteItemConfirm () {
      const slotResult = await this.$store.dispatch('slots/deleteSlot', this.editingItem.slotId)
      if (slotResult) {
        this.snackbar = true
        this.snackbarText = 'Slot deleted successfully'
        this.dialogDelete = false
      } else {
        this.snackbar = true
        this.snackbarText = 'Unable to delete slot'
        this.dialogDelete = false
      }
    },
    closeDelete () {
      this.dialogDelete = false
    },
    getSlots () {
      this.$store.dispatch('slots/fetchAllSlots')
    }
  }
}
</script>

<style scoped>

</style>
