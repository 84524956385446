import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { auth } from '../firebase.config'
import LoadScript from 'vue-plugin-load-script'

Vue.config.productionTip = false
Vue.use(LoadScript)
let app
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: function (h) { return h(App) }
    }).$mount('#app')
  }
  if (user) {
    store.dispatch('auth/setAdmin', user.uid)
  }
})
