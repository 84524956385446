import Vue from 'vue'
import Vuex from 'vuex'
import { attendees } from './attendees.module'
import { slots } from './slots.module'
import { auth } from './auth.module'
import { purpose } from './purpose.module'
import { users } from './users.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    attendees, slots, auth, purpose, users
  }
})
