import * as firebase from '../../firebase.config'

export const users = {
  namespaced: true,
  actions: {
    async getUserByITS ({
      commit,
      state
    }, itsID) {
      try {
        const docRef = firebase.userCollection.where('itsID', '==', itsID)
        const querySnapshots = await docRef.get()

        if (!querySnapshots.empty) {
          return { status: 'success', user: querySnapshots.docs[0].data() }
        } else {
          return { status: 'not_found', user: { email: null, regName: null, mobile: null, itsID: itsID } }
        }
      } catch (e) {
        return { status: 'fail', user: null }
      }
    },
    async addUser ({ commit }, data) {
      try {
        const batch = firebase.db.batch()
        const userRef = await firebase.userCollection.doc(data.itsID)
        batch.set(userRef, {
          itsID: data.itsID,
          regName: data.regName,
          mobile: data.mobile,
          email: data.email,
          gender: data.gender,
          type: 'guest'
        })
        await batch.commit()
        return { status: 'success', message: 'User added successfully' }
      } catch (e) {
        return { status: 'error', message: 'Error while adding user' }
      }
    }
  }
}
