import * as firebase from '../../firebase.config'
import { router } from '../router/index'

export const auth = {
  namespaced: true,
  state: {
    admin: ''
  },
  actions: {
    async loginUser ({ commit }, form) {
      try {
        const { user } = await firebase.auth.signInWithEmailAndPassword(
          form.username, form.password)
        commit('setAdmin', user.uid)
        return true
      } catch (e) {
        return false
      }
    },
    async logoutUser ({ commit }) {
      try {
        await firebase.auth.signOut()
        commit('setAdmin', '')
        router.push('/admin/login')
      } catch (e) {

      }
    },
    async setAdmin ({ commit }, val) {
      commit('setAdmin', val)
    }
  },
  mutations: {
    setAdmin (state, val) {
      state.admin = val
    }
  }
}
