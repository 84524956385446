<template>
  <v-app-bar
    class="appBar"
    color="teal accent-4"
    dark
    dense
  >
    <v-btn
      text
      @click="$router.push('/')"
    >
      <div class="d-flex align-center">
        <v-img
          alt="AET Logo"
          src="@/assets/logo.png"
          transition="scale-transition"
          width="75"
        />
      </div>
      <span class="mr-2">Waaz Pass Allocation</span>
    </v-btn>
    <v-spacer />
    <v-menu
      bottom
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-if="auth.admin">
          <v-btn
            text
            @click="$router.push('/admin/attendees')"
          >
            <span class="mr-2">Attendees</span>
          </v-btn>
        </v-list-item>
        <v-list-item v-if="auth.admin">
          <v-btn
            text
            @click="$router.push('/admin/slots')"
          >
            <span class="mr-6">Slots</span>
          </v-btn>
        </v-list-item>
        <v-list-item v-if="auth.admin">
          <v-btn
            text
            @click="$router.push('/admin/uploadData')"
          >
            <span class="mr-6">Data Upload</span>
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn
            v-if="$route.name !== 'Login' && !auth.admin"
            text
            @click="$router.push('/admin/login')"
          >
            <span class="mr-2">Admin Login</span>
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
          <v-btn
            v-else
            text
            @click="$router.push('/')"
          >
            <span class="mr-2">Home</span>
          </v-btn>
        </v-list-item>
        <v-list-item v-if="auth.admin">
          <v-btn
            text
            @click="logout()"
          >
            <span class="mr-2">Logout</span>
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'NavBar',
  computed: {
    ...mapState(['auth'])
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logoutUser')
    }
  }
}
</script>

<style scoped>
  .appBar {
    max-height: 48px;
  }
  .nav-link{
    color: white;
  }
</style>
