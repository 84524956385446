<template>
  <v-container
    class="pa-6"
    fluid
  >
    <v-card
      class="mx-auto"
      max-width="500"
    >
      <v-form
        ref="loginForm"
        @submit.prevent
      >
        <v-col>
          <v-row>
            <h3 class="pa-6">
              Login (Admin Portal)
            </h3>
          </v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="form.username"
              :rules="emailRules"
              label="Username"
              outlined
              required
            />
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="form.password"
              :rules="passwordRule"
              label="Password"
              outlined
              required
              type="password"
            />
          </v-col>
        </v-col>
        <v-row class="d-flex justify-center">
          <v-btn
            :loading="loading"
            class="d-flex mb-6"
            color="primary"
            cols="12"
            sm="6"
            @click="loginUser()"
          >
            Login
          </v-btn>
        </v-row>
      </v-form>
      <v-snackbar
        v-model="snackbar"
        :timeout="2000"
      >
        {{ snackbarText }}
      </v-snackbar>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: 'LoginForm',
  props: {
    emailRules: [],
    form: {},
    passwordRule: []
  },
  data: () => ({
    snackbar: false,
    snackbarText: '',
    loading: false
  }),
  methods: {
    async loginUser () {
      this.loading = true
      const loginResult = await this.$store.dispatch('auth/loginUser', this.form)
      this.loading = false
      if (loginResult) {
        this.snackbar = true
        this.snackbarText = 'Signed in successfully'
        this.$router.push('/admin/attendees')
      } else {
        this.snackbar = true
        this.snackbarText = 'Username / Password is invalid. Please try again.'
      }
    }
  }
}
</script>
