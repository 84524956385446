import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Attendees from '../views/admin/Attendees'
import Slots from '../views/admin/Slots'
import { auth } from '../../firebase.config'
import BethakPass from '../views/BethakPass'
import ViewPass from '../views/ViewPass'
import DataUpload from '../views/admin/DataUpload'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/admin/login',
    name: 'Login',
    component: Login,
    meta: { hideForAuth: true }
  },
  {
    path: '/pass',
    name: 'BethakPass',
    component: BethakPass,
    props: route => ({ query: route.query.itsID })
  },
  {
    path: '/viewPass',
    name: 'View Pass',
    component: ViewPass
  },
  {
    path: '/admin/attendees',
    name: 'Attendees',
    component: Attendees,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/uploadData',
    name: 'Upload Data',
    component: DataUpload,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/slots',
    name: 'Slots',
    component: Slots,
    meta: {
      requiresAuth: true
    }
  }
]

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const hideForAuth = to.matched.some(x => x.meta.hideForAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/admin/login')
  }
  if (hideForAuth && auth.currentUser) {
    next('/')
  } else {
    next()
  }
})

export default router
