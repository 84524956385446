<template>
  <v-container
    class="pa-6"
    fluid
  >
    <v-card
      class="mx-auto"
      max-width="960"
    >
      <v-card-text class="mb-6">
        <h3 class="pb-6 text-center">
          View/Manage Waaz Pass
        </h3>
        <v-divider class="py-6" />
        <ViewPassForm @getPassData="fetchDetails" />
        <v-divider class="py-6" />
        <v-data-table
          v-if="tableData.length > 0"
          :headers="header"
          :items="tableData"
          :loading="tableLoader"
          hide-default-footer
          :search="searchValue"
          :sort-by="['slotDate', 'date']"
          :sort-desc="[true, true]"
          class="elevation-1"
          multi-sort
        >
          <template #top>
            <v-row class="mt-2 px-2 d-flex align-start justify-start">
              <v-col
                class="d-flex justify-center align-center"
                md="7"
              >
                <v-text-field
                  v-model="searchValue"
                  clear-icon="mdi-close-circle-outline"
                  clearable
                  label="Search Pass"
                  prepend-inner-icon="mdi-magnify"
                  solo
                />
              </v-col>
            </v-row>
          </template>
          <template #item.action="{ item }">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-btn
                    text
                    @click="editItem(item)"
                  >
                    <span class="mr-2">Change Venue</span>
                  </v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn
                    text
                    @click="deleteItem(item)"
                  >
                    <span class="mr-2">Cancel Pass</span>
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
      @keypress.esc="closeDelete"
    >
      <v-card>
        <v-card-title>Are you sure you want to cancel the pass?</v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="closeDelete"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteItemConfirm"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogEdit"
      max-width="500px"
    >
      <v-card>
        <v-card-title>Edit Pass</v-card-title>
        <v-card-text>
          <v-form
            ref="editingSlotForm"
            v-model="isEditingFormValid"
          >
            <v-col
              cols="12"
              md="12"
            >
              <v-select
                v-model="editingItem.venue"
                :item-text="getSlotText"
                item-value="slotVenue"
                return-object
                persistent-hint
                :items="getAllSlotsByDateAndGender(oldItem.gender, oldItem.date)"
                :rules="venueRules"
                label="Waaz Seating Location"
                outlined
                required
              />
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="dialogEdit = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="updateVenue"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="
        snackType === 'success'
          ? 'green'
          : snackType === 'error'
            ? 'red'
            : 'yellow'
      "
      :timeout="2000"
    >
      {{ snackText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import ViewPassForm from '../components/ViewPassForm'
import { mapGetters } from 'vuex'

export default {
  name: 'ViewPass',
  components: {
    ViewPassForm
  },
  data: () => ({
    form: {
      venue: null,
      gender: null
    },
    venueRules: [v => !!v || 'Waaz Seating Location is required for Pass allocation'],
    dialogDelete: false,
    oldItem: {},
    editingItem: {
      venue: {}
    },
    isEditingFormValid: false,
    dialogEdit: false,
    snackbar: false,
    tableLoader: false,
    isValid: false,
    searchValue: '',
    snackText: '',
    tableData: [],
    snackType: 'success',
    header: [
      {
        text: 'Hijri Date',
        sortable: true,
        value: 'slotDate'
      },
      {
        text: 'Gregorian Date',
        sortable: true,
        value: 'date'
      },
      {
        text: 'Venue',
        sortable: false,
        value: 'venue'
      },
      {
        text: 'Actions',
        sortable: false,
        value: 'action'
      }
    ]
  }),
  computed: {
    ...mapGetters({
      getAllSlotsByDateAndGender: 'slots/getAllSlotsByDateAndGender',
      venues: 'slots/getAllVenues'
    })
  },
  async mounted () {
    await this.fetchAllSlots()
  },
  methods: {
    async fetchAllSlots () {
      await this.$store.dispatch('slots/fetchAllSlots')
    },
    getSlotText (slot) {
      return `${slot.slotDate} ( ${slot.slotVenue} )`
    },
    validateForm () {
      return this.$refs.editingSlotForm.validate()
    },
    editItem (item) {
      this.oldItem = JSON.parse(JSON.stringify(item))
      this.dialogEdit = true
    },
    async updateVenue () {
      if (this.validateForm()) {
        const updateResult = await this.$store.dispatch('attendees/updatePass', {
          oldData: this.oldItem,
          newData: this.editingItem
        })
        if (updateResult) {
          this.showSnackNotification('Pass updated successfully', 'success')
          const data = this.tableData.find(data => data.slotId === this.oldItem.slotId)
          data.venue = this.editingItem.venue.slotVenue
          this.closeEdit()
        } else {
          this.showSnackNotification('Unable to update pass', 'error')
        }
      }
    },
    deleteItem (item) {
      this.dialogDelete = true
      this.editingItem = item
    },
    async deleteItemConfirm () {
      const slotResult = await this.$store.dispatch('attendees/deletePassById', this.editingItem)
      if (slotResult) {
        const index = this.tableData.findIndex(data => data.id === this.editingItem.id)
        this.tableData.splice(index, 1)
        this.showSnackNotification('Pass cancelled successfully', 'success')
        this.closeDelete()
      } else {
        this.showSnackNotification('Unable to cancel pass', 'error')
        this.closeDelete()
      }
    },
    closeEdit () {
      this.dialogEdit = false
      this.editingItem.venue = {}
    },
    closeDelete () {
      this.dialogDelete = false
    },
    async fetchDetails (itsID) {
      const data = await this.$store.dispatch('attendees/getPassesByITS', itsID)
      if (data.status === 'success') {
        this.tableData = data.data
      } else if (data.status === 'not_found') {
        this.tableData = []
        this.showSnackNotification('No Passes Found', 'error')
      } else {
        this.tableData = []
        this.showSnackNotification('Error while fetching pass details', 'error')
      }
    },
    showSnackNotification (text, type) {
      this.snackText = text
      this.snackType = type
      this.snackbar = true
    },
    async showPass (item) {
      const data = JSON.parse(JSON.stringify(item))
      data.venue = {}
      data.venue.slotDate = item.slotDate
      data.venue.date = item.date
      data.venue.slotVenue = item.venue
      await this.$router.push({
        path: '/pass',
        query: {
          data: JSON.stringify(data)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
