<template>
  <v-container>
    <v-form
      ref="vajebaatForm"
      v-model="valid"
      class="text-center"
    >
      <v-container>
        <v-row>
          <v-col
            :offset="getOffset()"
            cols="12"
            lg="6"
            md="6"
            sm="12"
          >
            <v-text-field
              v-model="form.itsID"
              :rules="ITSValidationRules"
              label="ITS ID"
              outlined
              required
              @input="checkForUser($event)"
            />
            <v-text-field
              v-model="form.itsID"
              class="d-none"
              :rules="ITSValidationRules"
              label="ITS ID"
              outlined
              required
              @input="checkForUser($event)"
            />
          </v-col>
          <v-col
            v-if="user"
            :cols="form.regName ? '8':'6'"
            :md="form.regName ? '8':'6'"
            sm="12"
            xs="12"
          >
            <v-text-field
              v-model="form.regName"
              label="Full Name"
              :rules="regNameRules"
              outlined
              :disabled="!!user.regName"
            />
          </v-col>
          <v-col
            v-if="userType ==='new'"
            cols="6"
            md="6"
            sm="12"
          >
            <v-select
              v-if="!user.gender"
              v-model="form.gender"
              label="Gender"
              :rules="genderRule"
              outlined
              :items="['Male','Female']"
            />
          </v-col>
          <v-col
            v-if="userType ==='new'"
            cols="6"
            md="6"
            sm="12"
          >
            <v-text-field
              v-if="!user.mobile"
              v-model="form.mobile"
              label="Mobile"
              outlined
              :rules="mobileRules"
            />
          </v-col>
          <v-col
            v-if="userType ==='new'"
            cols="6"
            md="6"
            sm="12"
          >
            <v-text-field
              v-if="!user.email"
              v-model="form.email"
              label="Email"
              outlined
              :rules="emailRules"
            />
          </v-col>
          <v-col
            v-if="user && form.gender"
            cols="12"
            lg="6"
            md="6"
          >
            <v-select
              v-model="form.venue"
              :item-text="getSlotText"
              return-object
              persistent-hint
              :items="getAllSlotsByGender(form.gender)"
              :rules="venueRules"
              label="Waaz Seating Location"
              outlined
              required
            />
          </v-col>
        </v-row>
      </v-container>
      <v-row
        v-if="user"
        class="d-flex justify-center mb-6"
      >
        <v-btn
          :loading="passBtnLoading"
          class="d-flex"
          :disabled="!validateForm()"
          color="primary"
          cols="12"
          sm="6"
          @click="allocatePass()"
        >
          Allocate Pass
        </v-btn>
      </v-row>
      <v-row
        v-if="errMsg"
        class="d-flex justify-center mb-6"
      >
        <h4 class="error--text">
          {{ errMsg }}
        </h4>
      </v-row>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :color="
        snackType === 'success'
          ? 'green'
          : snackType === 'error'
            ? 'red'
            : 'yellow'
      "
      :timeout="2000"
    >
      {{ snackText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { VajebaatFormTemplate } from '@/templates/vajebaat_pass_template'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'RegistrationForm',
  data: () => ({
    user: undefined,
    form: {
      itsID: null,
      email: null,
      mobile: null,
      regName: null,
      venue: null,
      gender: null
    },
    valid: false,
    regNameRules: [v => !!v || 'Name is required'],
    genderRule: [v => !!v || 'Gender is required'],
    ITSValidationRules: [v => !!v || 'ITS is required', v => /^[0-9]{8}$/.test(v) || 'Please enter valid ITS ID'],
    mobileRules: [
      v => !!v || 'Mobile No is required',
      v => /^(?:(?:\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/.test(v) || 'Please enter 10 digit mobile number'
    ],
    venueRules: [v => !!v || 'Waaz Seating Location is required for Pass allocation'],
    errMsg: '',
    passBtnLoading: false,
    emailRules: [v => !!v || 'E-mail is required', v => /.+@.+/.test(v) || 'E-mail must be valid'],
    snackbar: false,
    snackText: '',
    snackType: 'success',
    userType: 'old'
  }),
  computed: {
    ...mapGetters({
      getAllSlotsByGender: 'slots/getAllSlotsByGender'
    })
  },
  async mounted () {
    await this.fetchAllSlots()
  },
  methods: {
    getSlotHint () {
      if (this.form.venue) {
        return `${this.form.venue.slotQty} slots`
      }
      return 'select a slot'
    },
    getOffset () {
      if (this.user) {
        return '0'
      }
      return '1'
    },
    validateForm () {
      return this.$refs.vajebaatForm.validate()
    },
    async fetchAllSlots () {
      await this.$store.dispatch('slots/fetchAllSlots')
    },
    async checkForUser (event) {
      if (event.toString().match(/^[0-9]{8}$/)) {
        await this.fetchUserDetails(event)
      }
    },
    sendEmail (data) {
      this.$loadScript('https://smtpjs.com/v3/smtp.js').then(() => { // this Promise return nothing!!!
        window.Email.send({
          Host: 'smtp.gmail.com',
          Username: 'aet.vajebaat@blrjmt.com',
          Password: 'kgflfnnaxifkacqi',
          To: this.form.email,
          From: 'ashara@blrjmt.com',
          Subject: `Gadeere Khum 1442H ( ${this.form.venue.slotVenue} )Pass Allocation - ${this.form.itsID}`,
          Body: VajebaatFormTemplate(data)
        }).then(

        )
      }).catch(() => {
        this.showSnackNotification('Error while sending email.', 'error')
      })
    },
    async fetchUserDetails (value) {
      const data = await this.$store.dispatch('users/getUserByITS', value)
      if (data.status === 'not_found') {
        this.userType = 'new'
      } else {
        this.userType = 'old'
      }

      if (data.status === 'success' || data.status === 'not_found') {
        this.user = data.user
        this.form.regName = this.user.regName
        this.form.email = this.user.email
        this.form.mobile = this.user.mobile
        this.form.gender = this.user.gender
      } else {
        this.showSnackNotification('Error while fetching user details', 'error')
      }
    },
    showSnackNotification (text, type) {
      this.snackText = text
      this.snackType = type
      this.snackbar = true
    },
    async allocatePass () {
      this.errMsg = ''
      this.passBtnLoading = true
      if (this.validateForm()) {
        const data = { ...this.form }
        data.createdAt = new Date().toLocaleTimeString('en-US')
        data.id = uuidv4()
        if (!this.user.email) {
          await this.$store.dispatch('users/addUser', data)
        }
        const result = await this.$store.dispatch('attendees/allocatePass', data)
        if (result && result.type === 'error') {
          this.errMsg = result.message
        } else if (result && result.type === 'success') {
          this.sendEmail(data)
          await this.$router.push({
            path: '/pass',
            query: {
              data: JSON.stringify(data)
            }
          })
        } else {
          this.errMsg = 'Unable to allocate pass. Please try again.'
        }
      }
      this.passBtnLoading = false
    },
    getSlotText (slot) {
      return `${slot.slotVenue} ( Availability : ${slot.slotQty} )`
    }
  }
}
</script>
<style scoped>

</style>
