<template>
  <v-app>
    <NavBar />
    <v-main>
      <router-view>
        <Home />
      </router-view>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import NavBar from './components/NavBar'
import Home from './views/Home'
import Footer from './components/Footer'

export default {
  name: 'App',

  components: {
    Home,
    NavBar,
    Footer
  },

  data: () => ({}),
  methods: {
    demo () {
    }
  }
}
</script>
