/* eslint-disable vue/html-indent */
/* eslint-disable vue/html-indent */
<template>
  <v-container
    class="pa-6"
    fluid
  >
    <v-card
      class="mx-auto"
      max-width="960"
    >
      <v-card-text class="mb-6">
        <h3 class="pb-6 text-center">
          Waaz Pass Self Allocation System
        </h3>
        <v-alert
          outlined
          type="info"
          border="left"
          color="teal"
          dark
        >
          <v-row align="center">
            <v-col class="text-center">
              <strong>Self Allocation is live for Gadeere Khum Waaz Mubarak, 18th Zilhajj 1442H<br>
                Waaz Time : 8AM </strong><br>
              Mumineen/Mumenaat who want to allocate themselves for Rahat Block can select Sehan - Rahat (Main Masjid Sehan for Gents and Ladies) for this miqaat.
            </v-col>
          </v-row>
        </v-alert>
        <v-divider />
        <RegistrationForm />
        <v-divider />
        <ul class="py-6">
          <li>
            This facility is currently available to mumineen registered at
            <strong>Burhani Masjid,Bangalore</strong> only.
          </li>
          <li>Government guidelines must be followed at all times.</li>
          <li>Wearing a mask while entering, exiting, and sitting in Masjid is compulsory.</li>
          <li>Maintain social distance while in Masjid as well as during entry & exit.</li>
          <li>Mumineen with any symptom of cough and cold should not come to Masjid.</li>
        </ul>
        <v-divider />
        <p class="pt-6">
          <strong>Please Note:</strong>
        </p>
        <ol class="pb-6">
          <li>
            Registration is compulsory to allow for proper organisation and flow management for Waaz.
          </li>
          <li>
            The seating location dropdown will have available passes for that location at the time of
            booking.
          </li>
          <li>
            Passes for all seating locations will be allocated on first come first serve basis and the
            dropdown will have the slots accordingly.
          </li>
          <li>
            On successful allocation of pass you will see a pass on the device, Save pass screenshot on your
            device. You will also recieve an email with your pass details post allocation.
          </li>
          <li>
            Any help or assitance required regarding pass allocation, Please contact the following number for assistance via Whatsapp only. <a href="https://wa.me/%2B917899458410">+917899458410</a>
          </li>
        </ol>
        <v-btn
          color="primary"
          class="text-center"
          large
          rounded
          text
          to="/viewPass"
        >
          View/Modify/Cancel Pass
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import RegistrationForm from '../components/RegistrationForm'

export default {
  name: 'Home',
  components: { RegistrationForm }
}
</script>
