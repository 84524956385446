<template>
  <div>
    <LoginForm
      :email-rules="emailRules"
      :form="form"
      :password-rule="passwordRule"
    />
  </div>
</template>

<script>
import LoginForm from '../components/LoginForm'

export default {
  name: 'Login',
  components: { LoginForm },
  data: () => ({
    form: {
      username: '',
      password: ''
    },
    emailRules: [v => !!v || 'E-mail is required', v => /.+@.+/.test(v) || 'E-mail must be valid'],
    passwordRule: [v => !!v || 'Password is required']
  })
}
</script>
