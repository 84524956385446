import * as firebase from '../../firebase.config'

export const purpose = {
  namespaced: true,
  state: {
    allPurpose: []
  },
  actions: {
    async getAllPurpose ({ commit }) {
      const purposeList = []
      try {
        const data = await firebase.purposeCollection.get()
        if (data.docs.length > 0) {
          for (const purposeDoc of data.docs) {
            purposeList.push(purposeDoc.data())
          }
          commit('setAllPurpose', purposeList)
        }
      } catch (e) {

      }
    }
  },
  mutations: {
    setAllPurpose (state, val) {
      state.allPurpose = val
    }
  },
  getters: {
    getPurposeById: (state) => (id) => {
      return state.allPurpose.find(purpose => purpose.purposeId === id)
    }
  }
}
